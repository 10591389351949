import { render, staticRenderFns } from "./HomeBoxFigures.vue?vue&type=template&id=9c2d5742&scoped=true"
import script from "./HomeBoxFigures.vue?vue&type=script&lang=js"
export * from "./HomeBoxFigures.vue?vue&type=script&lang=js"
import style0 from "./HomeBoxFigures.vue?vue&type=style&index=0&id=9c2d5742&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c2d5742",
  null
  
)

export default component.exports